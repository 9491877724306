<template>
	<article :key="counterComponentKey" class="flex flex-col bg-white justify-between rounded-lg shd py-4  w-full cursor-pointer transite relative" 
		:class="{'border-2 !border-[#0DAC5C]': showBorder}" @click="setSelectedVehicle(data)"
	>
		<div v-if="showBadge" class="px-2.5 py-1.5  flex items-center bg-[#0DAC5C] text-white absolute right-0 text-xs gap-2 rounded-l">
			<Icon name="bus" class="w-3" />
			{{ data.count }} {{ data.count > 1 ? 'Vehicles' : 'Vehicle' }}
		</div>
		<div class="center px-5">
			<img :src="data.images[0]" alt="car image" class="object-cover rounded-lg w-full">
		</div>

		<div class="flex justify-between gap-[16px] flex-wrap gap-y-[8px] mt-4 w-full px-5">
			<div class="flex flex-col ">
				<h4 class="font-medium text-sm">
					{{ data.name }}
				</h4>
				<span class="w-60 text-neut6 text-[11px]">{{ truncate(data.description, 70) }}</span>
			</div>
			<div class="flex flex-col w-auto">
				<span class="text-neut6 text-[11px] ">Starting from</span>
				<h4 class="font-bold !text-[#0DAC5C]">
					{{ convertToCurrency(data.price) }}
				</h4>
			</div>
		</div>
		<footer class="border-top flex px-5 pt-3 mt-5 gap-2">
			<div v-for="n in data.amenities" :key="n.name" class="badge">
				<img :src="n.image" alt="icon" class="w-4">
				{{ n.short_name }}
			</div>
			<span class="flex items-center justify-center ml-auto gap-2 text-sm">
				<Icon name="star" class="w-4" />
				<p class="p-0 m-0">5.0</p>
			</span>
		</footer>
	</article>
</template>

<script setup>
import { computed, defineProps} from 'vue'
import { truncate, convertToCurrency } from '@/composables/utils'
import { useCharterVehicleUsage, counterComponentKey } from '../composables'
import Icon from './icon.vue'

const { setSelectedVehicle, selectedVehicle, selectedVehiclesList } = useCharterVehicleUsage()

const props = defineProps({
  data: {
    type: Object,
    required: false,
    default: 0
  }
})


const showBorder = computed(() => {
	if (selectedVehicle.value.id === props.data.id) return true
	return selectedVehiclesList.value.some((vehicle) => {
		return vehicle.id === props.data.id
	})
})

const showBadge = computed(() => {
	return selectedVehiclesList.value.some((vehicle) => {
		return vehicle.id === props.data.id
	})
})
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}
.shd{
box-shadow: 0px 2px 8px 0px rgba(94, 94, 94, 0.12);
}
footer{
	@apply !z-10
}
.badge{
    @apply text-xs font-medium text-[#575A65] gap-1.5 rounded-full  px-2.5 py-1.5 bg-[#fafafa]0 flex items-center
}
</style>
