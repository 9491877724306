<template>
	<div class="relative h-full flex flex-col w-full ">
		<section class="h-full flex flex-col w-full py-[36px] pb-[160px] px-[32px] gap-5 relative overflow-y-auto">
			<div class="w-full flex justify-between items-center">
				<span class="text-xs flex gap-2">
					<Icon name="info_2" class="w-[16px]" />
					Vehicles would differ from what is displayed
				</span>
				<Icon name="close" class="w-[24px] cursor-pointer" @clicked="selectedVehicle = {}; step = 1" />
			</div>

			<div class="center px-5  bg-[#fafafa]0 rounded-lg p-4">
				<img :src="selectedVehicle.images[count]" alt="car image" class=" object-cover rounded-lg h-[160px] ">
			</div>

			<div class="flex justify-between items-center">
				<div class="flex flex-col justify-start items-start">
					<h1 class="text-xl font-bold">
						{{ selectedVehicle.name }}
					</h1>
					<span class="flex items-center justify-center gap-1.5  text-sm ">
						<Icon name="star" class="w-[16px]" />
						<p class="p-0 m-0">5.0 Ratings</p>
					</span>
				</div>
				<div class="flex flex-col w-auto">
					<span class="text-neut6 text-[11px]">Starting from</span>
					<h4 class="font-bold text-[#0DAC5C]">
						{{ convertToCurrency(selectedVehicle.price) }}
					</h4>
				</div>
			</div>

			<p class="text-[#6E717C] text-sm">
				{{ selectedVehicle.description }}
			</p>

			<div class="flex flex-col gap-4">
				<h2 class="font-bold">
					Amenities
				</h2>
				<div class="flex gap-6">
					<div v-for="n in selectedVehicle.amenities" :key="n.name" class="flex flex-col center font-bold text-[#575A65] gap-2">
						<img :src="n.image" alt="icon" class="w-[56px] border border-gray6 rounded-full p-[16px]">
						{{ n.short_name }}
					</div>
				</div>
			</div>

			<div class="flex flex-col gap-4">
				<h2 class="font-bold">
					Things to know
				</h2>
				<div class="flex flex-col gap-4">
					<span class="text-xs flex gap-2">
						<Icon name="pet" class="w-[16px]" />
						Pets are not allowed inside vehicle
					</span>
					<span class="text-xs flex gap-2">
						<Icon name="smoking" class="w-[16px]" />
						No smoking
					</span>
					<span class="text-xs flex gap-2">
						<Icon name="bus" class="w-[16px]" />
						Check into vehicle 30mins before time.
					</span>
				</div>
			</div>
		</section>
		<footer class="absolute bottom-0 inset-x-0 border-top p-[16px] grid grid-cols-2 items-end gap-[16px] bg-white">
			<div class="flex flex-col" :key="counterComponentKey">
				<label for="name">Number of buses</label>
				<span class="border border-[#E5E9F2] px-4 py-2 rounded-md">
					<Counter :modelValue="selectedVehicle.count" @change="(val) => updateCount(val)" />
				</span>
			</div>

			<button class="auth-form-btn border-none" @click="addToList">
				Add to charter
			</button>
		</footer>
	</div>
</template>

<script setup>
import {ref} from 'vue'
import { useCharterVehicleUsage, counterComponentKey } from '../composables'
import { convertToCurrency } from '@/composables/utils'
import Icon from './icon.vue'
import Counter from './counter.vue'

const { selectedVehicle, selectedVehiclesList, step } = useCharterVehicleUsage()
const count = ref(0)

const addToList = () => {
	if (selectedVehiclesList.value.length > 0) {
		const index = selectedVehiclesList.value.findIndex((item) => item.id === selectedVehicle.value.id)
		if (index > -1) {
			selectedVehiclesList.value[index] = selectedVehicle.value
			selectedVehicle.value = {}
			return
		}
	}
	selectedVehiclesList.value.push(selectedVehicle.value)
	selectedVehicle.value = {}
}

const updateCount = (num) => {
	selectedVehicle.value.count = num
	counterComponentKey.value++
}
</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}

</style>
