<template>
	<div class="relative h-full flex flex-col w-full">
		<section class="flex flex-col  h-full w-full py-[28px] pb-[112px] gap-[20px] relative overflow-y-auto">
			<div class="flex gap-[16px] text-lg text-black mb-[28px] cursor-pointer font-bold px-[16px]" @click="step=1">
				<icon name="back" class="w-[28px] lg:hidden" />
				Charter checkout
			</div>

			<div class="flex flex-col px-[16px] gap-[16px]" :key="counterComponentKey">
				<div v-for="(vehicle,index) in selectedVehiclesList" :key="vehicle.name" class="flex flex-col shd rounded-lg ">
					<div class="flex items-center justify-between py-[24px] px-[16px] gap-[16px]">
						<div class="flex items-center gap-[8px]">
							<Icon name="trash" class="w-[20px] cursor-pointer text-[red]" @clicked="removeVehicle(vehicle)" />
							<div class="flex items-center justify-center px-[20px] bg-[#fafafa] rounded-lg p-[16px]">
								<img :src="vehicle.images[0]" alt="car image" class=" object-cover rounded-lg w-[60px]">
							</div>
							<span class="flex flex-col">
								<h1 class="text-base font-bold text-black">{{ vehicle.name }}</h1>
								<p class="text-xs">No of vehicles: <span class="font-bold">{{ vehicle.count }}</span></p>
							</span>
						</div>
						<div class="flex flex-col w-auto">
							<span class="text-[#737876] text-[11px]">Starting from</span>
							<h4 class="font-bold text-[#0DAC5C]">
								{{ convertToCurrency(vehicle.price * vehicle.count) }}
							</h4>
						</div>
					</div>
					<footer class="border-top flex justify-between items-center px-[16px] py-[16px] mt-[20px] gap-2">
						<span class="text-xs">Vehicle quantity</span>
						<Counter :modelValue="selectedVehiclesList[index].count" @change="(val) => updateCount(val,index)" class="!w-[128px]" />
					</footer>
				</div>
			</div>
		</section>
		<footer class="absolute bottom-0 inset-x-0 border-top p-[16px] grid grid-cols-1 items-end gap-[16px] bg-white">
			<button class="auth-form-btn border-none" @click="step=3">
				Proceed
			</button>
		</footer>
	</div>
</template>

<script setup>
import {ref} from 'vue'
import { useCharterVehicleUsage, counterComponentKey } from '../composables'
import { convertToCurrency } from '@/composables/utils'
import Icon from './icon.vue'
import Counter from './counter.vue'

const { selectedVehiclesList, step } = useCharterVehicleUsage()
const removeVehicle = (vehicle) => {
	vehicle.count = 1
	selectedVehiclesList.value = selectedVehiclesList.value.filter((v) => v.id !== vehicle.id)
}

const updateCount = (num, index) => {
	selectedVehiclesList.value[index].count = num
	counterComponentKey.value++
}

</script>

<style scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}
.shd{
box-shadow: 0px 2px 8px 0px rgba(94, 94, 94, 0.12);
}
</style>
