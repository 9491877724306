<template>
	<div class="main-content flex flex-col">
		<div class="header bg-white border-bottom">
			<div class="container-fluid">
				<div class="header-body border-0">
					<div class="row align-items-end">
						<div class="col">
							<h6 class="header-pretitle">Overview</h6>
							<h1 class="header-title">Create Rentals</h1>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid flex-grow flex flex-col " style="height: calc(100vh - 150px);">
			<div class="flex-grow h-full flex lg:flex-row gap-6">
				<div class="flex flex-col gap-8 flex-grow h-full">
					<div class="flex flex-col">
						<h1 class="text-2xl text-[#000005] font-bold">
							Hire a bus
						</h1>
						<p class="text-[#595E5C]">
							Explore our diverse selection of vehicle categories
						</p>
						<!-- <p>current step is: {{ step }}</p> -->
					</div>
					<div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4 overflow-y-auto">
						<template v-if="loading">
							<boxLoader v-for="n in 4" :key="n" />
						</template>
						<template v-else>
							<VehicleCard v-for="n,i in vehicles" :key="i"  :data="n"/>
						</template>
					</div>
				</div>
				<div class="h-full bg-white border-left w-full z-40"
					:class="{'hidden':showOnSmallScreen && step < 2, 'fixed top-0 left-0' : showOnSmallScreen && step >= 2, 'relative md:max-w-[450px]': !showOnSmallScreen}"
				>
					<section v-if="step === 3" class="lg:flex flex-col h-full !z-[40] w-full inset-y-0 bg-white">
						<CharterForm />
					</section>
					<section v-else class="lg:flex flex-col h-full !z-[40] w-full inset-y-0 bg-white">
						<SelectedVehicle v-if="Object.keys(selectedVehicle).length" />
						<SelectedVehicleList v-else-if="Object.keys(selectedVehicle).length === 0 && selectedVehiclesList.length" />
						<div v-else id="emptyState" class="flex flex-col items-center justify-center h-full text-center my-auto">
							<Icon name="bus" class="w-20 text-[#C6C8C7] mb-4" />
							<p class="text-lg">
								No vehicle information available
							</p>
							<span class="text-[#737876] max-w-[230px]">Kindly select a bus to see more details</span>
						</div>
					</section>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import moment from 'moment'
import boxLoader from '@/components/core/boxLoader.vue'
import { computed, ref } from 'vue'
import { useFetchCharterVehicle, useCharterVehicleUsage } from '../composables'
import VehicleCard from '../components/vehicleCard.vue'
import SelectedVehicle from '../components/selectedVehicle.vue'
import SelectedVehicleList from '../components/selectedVehicleList.vue'
import { windowWidth } from '@/composables/utils'
import Icon from '../components/icon.vue'
import CharterForm from '../components/charterForm.vue'

const { fetch, loading, vehicles } = useFetchCharterVehicle()
const { selectedVehicle, selectedVehiclesList, step, clearSelection } = useCharterVehicleUsage()

const showOnSmallScreen = computed(() => {
	if(windowWidth.value < 768) return true
	return false
})

// const shouldShowOnSmallScreen = computed(() => {
// 	if (windowWidth.value > 1024) return true
// 	if (step.value === 1 && Object.keys(selectedVehicle.value).length) return true
// 	if (step.value === 2 && selectedVehiclesList.value.length) return true
// 	else return false
// })

fetch()
clearSelection()

</script>

<style lang="css" scoped>
h1, h2, h3, h4, h5, h6, p, span{
	margin: 0 !important;
}
</style>
